import {
  Button,
  Divider,
  Form,
  Image,
  Input,
  message,
  Modal,
  Upload,
} from "antd"
import {
  EyeOutlined,
  DeleteOutlined,
  CameraTwoTone,
  ExclamationCircleOutlined,
} from "@ant-design/icons"
import * as API from "common/api"
import * as COMMONS from "common/common"
import { TapAnimationComponent } from "components"
import { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { motion } from "framer-motion"

const checkRichMenuImageSize = (type, param, callback) => {
  const isSt1M = param.file.size / 1024 / 1024 < 1

  if (isSt1M) {
    const reader = new FileReader()
    reader.readAsDataURL(param.file)
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result
      const image = document.createElement("img")

      image.src = _loadedImageUrl
      image.addEventListener("load", async () => {
        const { width, height } = image

        if (type === "defaultRM") {
          if (width === 1200 && height === 810) {
            callback({
              file: param.file,
              preview: _loadedImageUrl,
            })
          } else {
            message.warning(COMMONS.WARN_IMAGE_SIZE_DIFFERENT)
          }
        } else if (type === "memberRM") {
          if (width === 1200 && height === 810) {
            callback({
              file: param.file,
              preview: _loadedImageUrl,
            })
          } else {
            message.warning(COMMONS.WARN_IMAGE_SIZE_DIFFERENT)
          }
        }
      })
    })
  } else {
    message.warning(COMMONS.WARN_IMAGE_TOO_BIG)
  }
}

const CustomUpload = styled(Upload)`
  .ant-upload {
    width: 100%;
  }
`

const RichmenuModal = (props) => {
  const {
    publicSettings,
    isRichmenuModalVisible,
    hideRichmenuModal,
    richmenus,
  } = props

  const isMountedRef = COMMONS.USE_IS_MOUNTED_REF()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [defaultRichmenuForm] = Form.useForm()
  const [memberRichmenuForm] = Form.useForm()

  const [defaultRichmenuImageFile, setDefaultRichmenuImageFile] =
    useState(undefined)
  const [memberRichmenuImageFile, setMemberRichmenuImageFile] =
    useState(undefined)

  const [modal, contextHolder] = Modal.useModal()

  const richmenuUpdateMutation = useMutation(API.ADMIN_UPDATE_RICHMENU, {
    onSuccess: () => {
      if (isMountedRef.current) {
        message.success(COMMONS.SUCCESS_UPDATE_MSG)
        queryClient.invalidateQueries({
          queryKey: [API.QUERY_KEY_ADMIN_RICHMENUS],
        })
      }
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning({
          content: COMMONS.ERROR_SESSION_MSG,
          key: COMMONS.MESSAGE_SESSION_ERROR_KEY,
        })
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error({
          content: COMMONS.ERROR_SYSTEM_MSG,
          key: COMMONS.MESSAGE_SYSTEM_ERROR_KEY,
        })
      } else {
        message.error({
          content: COMMONS.ERROR_SYSTEM_MSG,
          key: COMMONS.MESSAGE_SYSTEM_ERROR_KEY,
        })
      }
    },
  })

  const richmenuDeleteMutation = useMutation(API.ADMIN_DELETE_RICHMENU, {
    onSuccess: () => {
      if (isMountedRef.current) {
        message.success(COMMONS.SUCCESS_DELETE_MSG)
        queryClient.invalidateQueries({
          queryKey: [API.QUERY_KEY_ADMIN_RICHMENUS],
        })
      }
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning({
          content: COMMONS.ERROR_SESSION_MSG,
          key: COMMONS.MESSAGE_SESSION_ERROR_KEY,
        })
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error({
          content: COMMONS.ERROR_SYSTEM_MSG,
          key: COMMONS.MESSAGE_SYSTEM_ERROR_KEY,
        })
      } else {
        message.error({
          content: COMMONS.ERROR_SYSTEM_MSG,
          key: COMMONS.MESSAGE_SYSTEM_ERROR_KEY,
        })
      }
    },
  })

  useEffect(() => {
    if (!isRichmenuModalVisible) {
      defaultRichmenuForm.resetFields()
      memberRichmenuForm.resetFields()

      setDefaultRichmenuImageFile(undefined)
      setMemberRichmenuImageFile(undefined)
    }

    // eslint-disable-next-line
  }, [isRichmenuModalVisible])

  useEffect(() => {
    if (isRichmenuModalVisible) {
      if (richmenus?.defaultRM?.picUrl) {
        defaultRichmenuForm.setFieldsValue({
          richmenuImage: richmenus.defaultRM.picUrl,
          linkA: richmenus.defaultRM?.link1
            ? richmenus.defaultRM?.link1
            : undefined,
          linkB: richmenus.defaultRM?.link2
            ? richmenus.defaultRM?.link2
            : undefined,
          linkC: richmenus.defaultRM?.link3
            ? richmenus.defaultRM?.link3
            : undefined,
          linkD: richmenus.defaultRM?.link4
            ? richmenus.defaultRM?.link4
            : undefined,
        })

        setDefaultRichmenuImageFile({
          preview: `${API.SETTINGS_UPLOADS_URL}${richmenus.defaultRM.picUrl}`,
        })
      } else {
        defaultRichmenuForm.resetFields()

        setDefaultRichmenuImageFile(undefined)
      }

      if (richmenus?.memberRM?.picUrl) {
        memberRichmenuForm.setFieldsValue({
          richmenuImage: richmenus.memberRM.picUrl,
          linkA: richmenus.memberRM?.link1
            ? richmenus.memberRM?.link1
            : undefined,
          linkB: richmenus.memberRM?.link2
            ? richmenus.memberRM?.link2
            : undefined,
          linkC: richmenus.memberRM?.link3
            ? richmenus.memberRM?.link3
            : undefined,
          linkD: richmenus.memberRM?.link4
            ? richmenus.memberRM?.link4
            : undefined,
        })

        setMemberRichmenuImageFile({
          preview: `${API.SETTINGS_UPLOADS_URL}${richmenus.memberRM.picUrl}`,
        })
      } else {
        memberRichmenuForm.resetFields()

        setMemberRichmenuImageFile(undefined)
      }
    }

    // eslint-disable-next-line
  }, [richmenus, isRichmenuModalVisible])

  const handleRichmenuUpdate = (type, field, data) => {
    if (type === "defaultRM") {
      const paramData = {
        type: type,
        isDisplayed: richmenus.defaultRM?.isDisplayed,
        picUrl: field.file ? field.file : undefined,
        link1: data.linkA || "",
        link2: data.linkB || "",
        link3: data.linkC || "",
        link4: data.linkD || "",
      }

      richmenuUpdateMutation.mutate(paramData)
    } else if (type === "memberRM") {
      const paramData = {
        type: type,
        isDisplayed: richmenus.memberRM?.isDisplayed,
        picUrl: field.file ? field.file : undefined,
        link1: data.linkA || "",
        link2: data.linkB || "",
        link3: data.linkC || "",
        link4: data.linkD || "",
      }

      richmenuUpdateMutation.mutate(paramData)
    }
  }

  const handleRichmenuDelete = (type) => {
    const paramData = {
      type: type,
    }

    modal.confirm({
      title: "確認",
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: "リッチメニューを削除してもよろしいでしょうか？",
      okText: "削除",
      okButtonProps: {
        size: "large",
        type: "primary",
        danger: true,
      },
      cancelText: "閉じる",
      cancelButtonProps: {
        size: "large",
      },
      centered: true,
      onOk() {
        richmenuDeleteMutation.mutate(paramData)
      },
    })
  }

  return (
    <>
      <Modal
        title="リッチメニュー設定"
        open={isRichmenuModalVisible}
        onCancel={hideRichmenuModal}
        footer={null}
        getContainer={false}
        destroyOnClose
        centered
        bodyStyle={{
          maxHeight: "90vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <motion.div
          className="flex flex-col"
          variants={COMMONS.ANIMATION_VARIANT_STAGGER_CONTAINER}
          initial="hidden"
          animate="show"
          exit="hidden"
        >
          <Divider>登録前のリッチメニュー</Divider>
          <motion.div
            className="flex flex-col justify-center bg-amber-50 p-4 rounded mb-4"
            variants={COMMONS.ANIMATION_VARIANT_STAGGER_ITEM}
          >
            <Image
              src="/template.png"
              alt="テンプレート"
              preview={{
                mask: <EyeOutlined />,
                src: "/template.png",
                maskClassName: "rounded",
              }}
              className="w-full rounded object-contain"
              fallback="/no-image.png"
            />
            <p className="my-4">
              リッチメニューの画像は以下の要件を満たす必要があります
            </p>
            <ol className="custom-ol list-none list-outside mb-2">
              <li>画像フォーマット：JPEGまたはPNG</li>
              <li>画像の幅サイズ（ピクセル）：1200px</li>
              <li>画像の高さサイズ（ピクセル）：810px</li>
              <li>最大ファイルサイズ：1MB</li>
            </ol>
          </motion.div>
          <motion.div
            className="border border-custom-gray p-4"
            variants={COMMONS.ANIMATION_VARIANT_STAGGER_ITEM}
          >
            <Form
              name="defaultRichmenuForm"
              form={defaultRichmenuForm}
              onFinish={(data) => {
                handleRichmenuUpdate(
                  "defaultRM",
                  defaultRichmenuImageFile,
                  data
                )
              }}
              layout="vertical"
              requiredMark={false}
              preserve={false}
              initialValues={{
                richmenuImage: undefined,
              }}
              scrollToFirstError
            >
              <div className="flex flex-col">
                <div className="flex justify-end mb-4">
                  <TapAnimationComponent>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        handleRichmenuDelete("defaultRM")
                      }}
                      loading={richmenuDeleteMutation.isLoading}
                      disabled={richmenus.defaultRM ? false : true}
                    />
                  </TapAnimationComponent>
                </div>
                <Form.Item
                  name="richmenuImage"
                  className="whitespace-pre-wrap text-center"
                  help={`※このリッチメニューは、まだ会員ではないユーザーに\n表示されます`}
                  valuePropName="file"
                >
                  <CustomUpload
                    accept=".jpg, .jpeg, .png"
                    listType="picture"
                    maxCount={1}
                    showUploadList={false}
                    beforeUpload={() => {
                      return false
                    }}
                    onChange={async (param) => {
                      checkRichMenuImageSize(
                        "defaultRM",
                        param,
                        setDefaultRichmenuImageFile
                      )
                    }}
                  >
                    {defaultRichmenuImageFile?.preview ? (
                      <img
                        src={defaultRichmenuImageFile.preview}
                        alt="登録前のリッチメニュー"
                        style={{
                          maxHeight: "250px",
                        }}
                        className="w-full object-contain cursor-pointer"
                      />
                    ) : (
                      <div
                        style={{
                          height: "250px",
                          backgroundColor: "#fafafa",
                          border: "1px dashed #d9d9d9",
                        }}
                        className="flex justify-center items-center rounded cursor-pointer"
                      >
                        <TapAnimationComponent>
                          <Button
                            type="dashed"
                            icon={
                              <CameraTwoTone
                                twoToneColor={publicSettings?.PRIMARY_COLOR}
                              />
                            }
                          >
                            アップロード
                          </Button>
                        </TapAnimationComponent>
                      </div>
                    )}
                  </CustomUpload>
                </Form.Item>
                <Divider />
                <Form.Item
                  name="linkA"
                  className="mt-4"
                  rules={[
                    {
                      type: "url",
                      message: "入力のURLが有効なURLではありません",
                    },
                  ]}
                >
                  <Input
                    addonBefore="A部分のリンク先："
                    placeholder={`例：${API.SITE_URL}`}
                    onPressEnter={(e) => e.preventDefault()}
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  name="linkB"
                  className="mt-4"
                  rules={[
                    {
                      type: "url",
                      message: "入力のURLが有効なURLではありません",
                    },
                  ]}
                >
                  <Input
                    addonBefore="B部分のリンク先："
                    placeholder={`例：${API.SITE_URL}`}
                    onPressEnter={(e) => e.preventDefault()}
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  name="linkC"
                  className="mt-4"
                  rules={[
                    {
                      type: "url",
                      message: "入力のURLが有効なURLではありません",
                    },
                  ]}
                >
                  <Input
                    addonBefore="C部分のリンク先："
                    placeholder={`例：${API.SITE_URL}`}
                    onPressEnter={(e) => e.preventDefault()}
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  name="linkD"
                  className="mt-4"
                  rules={[
                    {
                      type: "url",
                      message: "入力のURLが有効なURLではありません",
                    },
                  ]}
                >
                  <Input
                    addonBefore="D部分のリンク先："
                    placeholder={`例：${API.SITE_URL}`}
                    onPressEnter={(e) => e.preventDefault()}
                    allowClear
                  />
                </Form.Item>
                <Divider />
                <div className="flex justify-center">
                  <TapAnimationComponent>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={richmenuUpdateMutation.isLoading}
                      disabled={
                        defaultRichmenuImageFile?.file ||
                        defaultRichmenuForm.getFieldValue("richmenuImage")
                          ? false
                          : true
                      }
                    >
                      設定する
                    </Button>
                  </TapAnimationComponent>
                </div>
              </div>
            </Form>
          </motion.div>
          <Divider>登録後のリッチメニュー</Divider>
          <motion.div
            className="flex flex-col justify-center bg-amber-50 p-4 rounded mb-4"
            variants={COMMONS.ANIMATION_VARIANT_STAGGER_ITEM}
          >
            <Image
              src="/template.png"
              alt="テンプレート"
              preview={{
                mask: <EyeOutlined />,
                src: "/template.png",
                maskClassName: "rounded",
              }}
              className="w-full rounded object-contain"
              fallback="/no-image.png"
            />
            <p className="my-4">
              リッチメニューの画像は以下の要件を満たす必要があります
            </p>
            <ol className="custom-ol list-none list-outside mb-2">
              <li>画像フォーマット：JPEGまたはPNG</li>
              <li>画像の幅サイズ（ピクセル）：1200px</li>
              <li>画像の高さサイズ（ピクセル）：810px</li>
              <li>最大ファイルサイズ：1MB</li>
            </ol>
          </motion.div>
          <motion.div
            className="border border-custom-gray p-4"
            variants={COMMONS.ANIMATION_VARIANT_STAGGER_ITEM}
          >
            <Form
              name="memberRichmenuForm"
              form={memberRichmenuForm}
              onFinish={(data) => {
                handleRichmenuUpdate("memberRM", memberRichmenuImageFile, data)
              }}
              layout="vertical"
              requiredMark={false}
              preserve={false}
              initialValues={{
                richmenuImage: undefined,
              }}
              scrollToFirstError
            >
              <div className="flex flex-col">
                <div className="flex justify-end mb-4">
                  <TapAnimationComponent>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        handleRichmenuDelete("memberRM")
                      }}
                      loading={richmenuDeleteMutation.isLoading}
                      disabled={richmenus.memberRM ? false : true}
                    />
                  </TapAnimationComponent>
                </div>
                <Form.Item
                  name="richmenuImage"
                  className="whitespace-pre-wrap text-center"
                  help={`※このリッチメニューは、会員のユーザーに\n表示されます`}
                  valuePropName="file"
                >
                  <CustomUpload
                    accept=".jpg, .jpeg, .png"
                    listType="picture"
                    maxCount={1}
                    showUploadList={false}
                    beforeUpload={() => {
                      return false
                    }}
                    onChange={async (param) => {
                      checkRichMenuImageSize(
                        "memberRM",
                        param,
                        setMemberRichmenuImageFile
                      )
                    }}
                  >
                    {memberRichmenuImageFile?.preview ? (
                      <img
                        src={memberRichmenuImageFile.preview}
                        alt="登録前のリッチメニュー"
                        style={{
                          maxHeight: "250px",
                        }}
                        className="w-full object-contain cursor-pointer"
                      />
                    ) : (
                      <div
                        style={{
                          height: "250px",
                          backgroundColor: "#fafafa",
                          border: "1px dashed #d9d9d9",
                        }}
                        className="flex justify-center items-center rounded cursor-pointer"
                      >
                        <TapAnimationComponent>
                          <Button
                            type="dashed"
                            icon={
                              <CameraTwoTone
                                twoToneColor={publicSettings?.PRIMARY_COLOR}
                              />
                            }
                          >
                            アップロード
                          </Button>
                        </TapAnimationComponent>
                      </div>
                    )}
                  </CustomUpload>
                </Form.Item>
                <Divider />
                <Form.Item
                  name="linkA"
                  className="mt-4"
                  rules={[
                    {
                      type: "url",
                      message: "入力のURLが有効なURLではありません",
                    },
                  ]}
                >
                  <Input
                    addonBefore="A部分のリンク先："
                    placeholder={`例：${API.SITE_URL}`}
                    onPressEnter={(e) => e.preventDefault()}
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  name="linkB"
                  className="mt-4"
                  rules={[
                    {
                      type: "url",
                      message: "入力のURLが有効なURLではありません",
                    },
                  ]}
                >
                  <Input
                    addonBefore="B部分のリンク先："
                    placeholder={`例：${API.SITE_URL}`}
                    onPressEnter={(e) => e.preventDefault()}
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  name="linkC"
                  className="mt-4"
                  rules={[
                    {
                      type: "url",
                      message: "入力のURLが有効なURLではありません",
                    },
                  ]}
                >
                  <Input
                    addonBefore="C部分のリンク先："
                    placeholder={`例：${API.SITE_URL}`}
                    onPressEnter={(e) => e.preventDefault()}
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  name="linkD"
                  className="mt-4"
                  rules={[
                    {
                      type: "url",
                      message: "入力のURLが有効なURLではありません",
                    },
                  ]}
                >
                  <Input
                    addonBefore="D部分のリンク先："
                    placeholder={`例：${API.SITE_URL}`}
                    onPressEnter={(e) => e.preventDefault()}
                    allowClear
                  />
                </Form.Item>
                <Divider />
                <div className="flex justify-center">
                  <TapAnimationComponent>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={richmenuUpdateMutation.isLoading}
                      disabled={
                        memberRichmenuImageFile?.file ||
                        memberRichmenuForm.getFieldValue("richmenuImage")
                          ? false
                          : true
                      }
                    >
                      設定する
                    </Button>
                  </TapAnimationComponent>
                </div>
              </div>
            </Form>
          </motion.div>
        </motion.div>
      </Modal>
      {contextHolder}
    </>
  )
}

export default RichmenuModal
